// @flow
import * as actions from 'client/constants/actions';
import * as api from 'api';
import _ from 'lodash';

import { APP_CONFIG, G_RECAPTCHA_TOKEN_NAME } from 'common/constants';
import { GET_ITEMS_DEFAULT_PARAMS } from 'admin/constants';

import { apiRequestAction } from './helpers/api-request';

export { uploadMedia, deleteMedia } from './media';
export * from './auth';

const API_URL = APP_CONFIG.domains.backend.url;

export const renameFile = (file, name) => {
	const blob = file.slice(0, file.size, file.type);
	return new File([blob], `${name}.${file.type.split('/')[1]}`, { type: file.type });
};

export function createItem<I: AbstractItem>(params: CreateItemParams<I> & { token: string }): any {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({ type: actions.CREATE_ITEM.START, payload: params });

			return new Promise((resolve, reject) => {
				const formData = new FormData();

				if (params.data.nameOfTheCandle) formData.append('nameOfTheCandle', params.data.nameOfTheCandle);
				if (params.data.birthYear) formData.append('birthYear', params.data.birthYear);
				if (params.data.deathYear) formData.append('deathYear', params.data.deathYear);
				if (params.data.freeText) formData.append('freeText', params.data.freeText);
				if (params.data.senderName) formData.append('senderName', params.data.senderName);
				if (params.token) formData.append(G_RECAPTCHA_TOKEN_NAME, params.token);
				if (params.data.mainPicture) formData.append('mainPicture', renameFile(params.data.mainPicture, 'mainPicture'));
				if (params.data.profilePictures) {
					_.map(params.data.profilePictures, (pic, index) => {
						formData.append('profilePictures[]', renameFile(pic, `profilePictures_${index}`));
					});
				}

				const request = new XMLHttpRequest();
				request.onload = () => {
					try {
						const response = JSON.parse(request.response);
						resolve({
							data: response,
						});
					} catch (e) {
						reject(e);
					}
				};
				request.open('POST', `${API_URL}/crud/create-lead`);
				request.send(formData);
			});
		} catch (e) {
			const msg = e.message;
			dispatch({ type: actions.CREATE_ITEM.FAIL, payload: msg });
		}

		// return dispatch(requestAction);
	};
}

export function getItems(params: GetItemsParams<>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_ITEMS,
			request: api.getItems,
			params: { ...GET_ITEMS_DEFAULT_PARAMS, ...params },
		});

		return dispatch(requestAction);
	};
}
export function getItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_ITEM,
			request: api.getItem,
			params,
		});

		return dispatch(requestAction);
	};
}

export function updateRealTimeDataBase(params: any): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.UPDATE_ITEM_REAL_TIME,
			request: api.updateRealTimeDataBase,
			params,
		});

		return dispatch(requestAction);
	};
}

/* eslint-disable no-nested-ternary, react/no-danger */
// @flow
import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

import SocialsForItem from 'client/components/common/SocialsForItem';
import { Responsive } from 'client/components/common';
// import { fileLink } from 'common/utils';

import { ROOT_ROUTE } from 'client/constants/routes';
import { formatLink } from 'common/utils';
import { URLParams } from 'client/utils';

import defaultCandle from 'assets/client/images/firstScreen/final_candel.png';
import arrowIcon from 'assets/client/images/firstScreen/left-arrow2.png';

import css from './ItemPopUp.scss';

type Props = {
	// item: LeadInfo,
	// activeItemPopUp: boolean,
	// setActiveItemPopUp: any,
	// setActiveIndex: any,
	// activeIndex: number,
	leads: Leads,
	item?: LeadInfo,
};

export default function ItemPopUp(props: Props) {
	const { item, leads } = props;
	const [isClose, setIsClose] = useState(true);
	const [itemLead, setItemLead]: any = useState(item);
	// const [leadsItem, setLeads]: any = useState({});
	const [activeIndex, setActiveIndex]: any = useState(0);
	// const [sliderImages, setSliderImages]: any = useState([]);
	const [isImageLoaded, setIsImageLoaded]: any = useState(false);
	const sliderRef: any = useRef();
	const location: any = useLocation();
	const history: any = useHistory();
	const imageParams = `?auto=compress&auto=format&&fit=facearea&facepad=10&faces=1`;
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	// const ogImage = formatLink(_.get(item, 'mainPicture.file.name', ''), '0', imageParamsOg) || shareImage;

	useEffect((): any => {
		const urlParams = URLParams.parse(location.search);
		const index = _.findIndex(leads.list, ['id', _.get(urlParams, 'p', '')]);

		// get item data
		if (index >= 0) {
			const itemData = leads.list[index];

			if (itemData) {
				setItemLead(itemData);
				setIsClose(false);
			}

			setActiveIndex(index);
		}
	}, [location]);

	useEffect(() => {
		// if (itemLead?.mainPicture?.file) {
		// 	_.map(itemLead?.profilePictures?.file, (itemPic: any) => {
		// 		const newArr = profilePictures.push(itemLead?.mainPicture?.file);
		// 	});
		// 	const profilePictures = [...itemLead?.profilePictures?.file];
		// 	console.info(itemLead?.profilePictures?.file);
		// 	setSliderImages(newArr);
		// }
	}, [itemLead]);

	const PrevArrow = () => {
		const onClickPrev = () => sliderRef.current.slickNext();

		return (
			<div className={cn(css.innerSlideArrow, css.prevArrow)} onClick={onClickPrev}>
				<span className={css.firstArrowLine}></span>
				<span className={css.secondArrowLine}></span>
			</div>
		);
	};

	const NextArrow = () => {
		const onClickNext = () => sliderRef.current.slickPrev();

		return (
			<div className={cn(css.innerSlideArrow, css.nextArrow)} onClick={onClickNext}>
				<span className={css.firstArrowLine}></span>
				<span className={css.secondArrowLine}></span>
			</div>
		);
	};
	const innerSliderSettings = {
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	const onClickCloseButton = () => {
		setIsClose(true);
		setTimeout(() => {
			history.replace({
				pathname: ROOT_ROUTE,
				search: ``,
			});
		}, 200);
	};

	const onNextItemClick = () => {
		const nextIndex = activeIndex - 1;

		if (nextIndex <= leads.list.length - 1) {
			const nextItem = leads.list[nextIndex];
			if (nextItem) {
				setActiveIndex(nextIndex);
				// history.push(ITEM_ROUTE.replace(':id', nextItem.id));
				history.replace({ pathname: ROOT_ROUTE, search: URLParams.stringify({ p: nextItem.id }) });
			}
		}
	};
	const onPrevItemClick = () => {
		const nextIndex = activeIndex + 1;
		if (nextIndex <= leads.list.length - 1) {
			const nextItem = leads.list[nextIndex];

			if (nextItem) {
				setActiveIndex(nextIndex);
				// history.push(ITEM_ROUTE.replace(':id', nextItem.id));
				history.replace({ pathname: ROOT_ROUTE, search: URLParams.stringify({ p: nextItem.id }) });
			}
		}
	};

	return (
		<>
			<div className={cn(css.itemPopUp, (item || itemLead) && !isClose && css.active)}>
				<div className={css.bg} onClick={onClickCloseButton}></div>
				{isMobile && (
					<div
						className={cn(css.closeButton, isClose && css.animationCloseButton, !isClose && css.animationCloseButtonFalse)}
						onClick={onClickCloseButton}
					>
						<span className={css.firstLine}></span>
						<span className={css.secondLine}></span>
					</div>
				)}
				<div className={css.popUpWindow}>
					{!isMobile && (
						<div
							className={cn(css.closeButton, isClose && css.animationCloseButton, !isClose && css.animationCloseButtonFalse)}
							onClick={onClickCloseButton}
						>
							<span className={css.firstLine}></span>
							<span className={css.secondLine}></span>
						</div>
					)}
					<div className={css.innerPopUpWrapper}>
						{_.findIndex(leads.list, ['id', leads.list[activeIndex + 1]?.id]) !== -1 && (
							<button className={cn(css.prevArrowGlobal, css.arrowLeft)} type="button" onClick={onPrevItemClick}>
								<img src={arrowIcon} alt="" className={cn(css.arrow)}></img>
							</button>
						)}
						{_.findIndex(leads.list, ['id', leads.list[activeIndex - 1]?.id]) !== -1 && (
							<button className={cn(css.nextArrowGlobal, css.arrowRight)} type="button" onClick={onNextItemClick}>
								<img src={arrowIcon} alt="" className={cn(css.arrow)}></img>
							</button>
						)}

						<div className={css.candleWrapper}>
							<div className={css.leftPart}>
								<div className={css.leftInner}>
									<div className={css.topBlock}>
										<h1 className={css.name}>{itemLead?.nameOfTheCandle} ז”ל</h1>
										<div className={css.year}>
											{(itemLead?.birthYear || itemLead?.deathYear) && (
												<div>
													{itemLead.deathYear ? itemLead.deathYear : '?'}-
													{itemLead.birthYear ? itemLead.birthYear : '?'}
												</div>
											)}
										</div>
									</div>
									<div className={css.descriptionBlock}>
										<p className={css.senderName}>{itemLead?.senderName ? <>מאת {itemLead?.senderName}</> : ''}</p>
										{itemLead?.freeText && (
											<p className={css.freeText} dangerouslySetInnerHTML={{ __html: itemLead?.freeText }} />
										)}
									</div>
								</div>
								{!isMobile && <SocialsForItem item={itemLead} isFromFullWidthItem={false} />}
							</div>
							<div className={cn(css.rightPart, _.isEmpty(itemLead?.profilePictures) && css.withOverflow)}>
								{!_.isEmpty(itemLead?.profilePictures) ? (
									<Slider className={css.slider} {...innerSliderSettings} ref={sliderRef}>
										{_.map(
											itemLead.mainPicture.file
												? [itemLead.mainPicture.file, ...itemLead?.profilePictures]
												: [...itemLead?.profilePictures],
											(picture: any, index: any) => {
												return (
													picture.url &&
													(picture.name.includes('videos') ? (
														<video src={picture.url} muted autoPlay controls key={picture.name}></video>
													) : (
														<div key={`${picture.name}-${index}`}>
															<img
																src={formatLink(picture.name, '0', imageParams)}
																alt=""
																className={css.candlePicture}
																onLoad={() => setIsImageLoaded(true)}
																style={{
																	display: !isImageLoaded ? 'none' : 'block',
																}}
															/>
														</div>
													))
												);
											}
										)}
									</Slider>
								) : itemLead?.mainPicture?.file?.url ? (
									<img
										src={formatLink(itemLead?.mainPicture.file.name, '0', imageParams)}
										alt=""
										className={css.defaultCandleImg}
										onLoad={() => setIsImageLoaded(true)}
										style={{ display: !isImageLoaded ? 'none' : 'block' }}
									/>
								) : (
									<img
										src={defaultCandle}
										alt=""
										className={css.defaultCandleImg}
										onLoad={() => setIsImageLoaded(true)}
										style={{ display: !isImageLoaded ? 'none' : 'block' }}
									/>
								)}
							</div>
							{isMobile && <SocialsForItem item={itemLead} isFromFullWidthItem />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

ItemPopUp.defaultProps = {
	item: {},
};
